import { Theme, ThemeStyles, ThemeTypography, ThemeTypographyStyles } from '@platform-ui-kit/components-library'
import Color from 'color'
import { parse } from 'css-box-shadow'
import { compact, get, replace } from 'lodash'

import { themesFonts } from './components/Typography/consts'
import { ThemeFont } from './components/Typography/types'
import { BoxShadow, BoxShadowParseResult, CreateTheme, FontTypes, MayBeNull } from './types'
import { IAppContext } from '../../app/context'

export const removeStopWords = (text: string, stopWords: string[]): string => {
  const pattern = new RegExp('\\b(' + stopWords.join('|') + ')\\b', 'gi')
  return text.replace(pattern, '')
}

export const removeExtension = (filename: string): string => {
  const dotIndex = filename.lastIndexOf('.')
  if (dotIndex !== -1) {
    return filename.substring(0, dotIndex)
  }
  return filename
}

export const removeNonAlphanumericFromEnd = (str: string): string => {
  const regex = /[^\w\d]+$/
  return str.replace(regex, '')
}

export const getDefaultBoxShadowValues = (): BoxShadow => ({
  x: 1,
  y: 1,
  blur: 1,
  spread: 1,
  color: '#808080',
  opacity: 0.5,
})

export const getInitialValues = (): CreateTheme => ({
  font: {
    family: 'Inter',
    url: 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900',
  },
  name: '',
  color: {
    primary: {},
    grey: {
      currSection: 'cold',
      cold: {
        '100_color': '#8d4747',
        '200_color': '#8D4747FF',
      },
      neutral: {
        '100_color': '#fd0028',
      },
    },
    dataviz: {
      cat: {
        currSection: 'dark',
      },
      seq: {
        currSection: 'brand',
      },
    },
  },
  objectStyles: {
    border: {
      radius: {
        xs: 0,
        s: 0,
        m: 0,
        l: 0,
        xl: 0,
        round: 0,
      },
    },
    boxShadow: {
      xs: {
        1: getDefaultBoxShadowValues(),
        2: getDefaultBoxShadowValues(),
      },
      s: {
        1: getDefaultBoxShadowValues(),
        2: getDefaultBoxShadowValues(),
      },
      m: {
        1: getDefaultBoxShadowValues(),
        2: getDefaultBoxShadowValues(),
      },
      brand: {
        1: getDefaultBoxShadowValues(),
        2: getDefaultBoxShadowValues(),
      },
      l: {
        1: getDefaultBoxShadowValues(),
        2: getDefaultBoxShadowValues(),
      },
    },
  },
  typography: {},
})

export const getBoxShadowFieldPath = (type: string) => `objectStyles.boxShadow.${type}`
export const getTypographyFieldPath = (type: string) => `typography.boxShadow.${type}`

const getBoxShadowStyleFromInfo = (data: BoxShadow) => {
  if (!data || !data.color) return ''

  try {
    const rgbColor = Color(data.color) as any
    return `${data.x}px ${data.y}px ${data.blur}px ${data.spread}px rgba(${rgbColor.color[0]}, ${rgbColor.color[1]}, ${rgbColor.color[2]}, ${data.opacity})`
  } catch (e) {
    return ''
  }
}

const getContrastScore = (contrast: number) => {
  if (contrast >= 7) {
    return 'AAA'
  }

  if (contrast >= 4.5) {
    return 'AA'
  }

  if (contrast >= 3) {
    return 'A'
  }

  return ''
}

export const getColorInfo = (color: string): { hex: string; opacity?: any } => {
  try {
    const rgbColor = Color(color)
    return {
      hex: rgbColor.hex().toLowerCase(),
      opacity: rgbColor.object().alpha,
    }
  } catch (e) {
    return { hex: '' }
  }
}

export const getColorContrast = (color: string) => {
  try {
    const rgbColor = Color(color)
    const contrastValue = rgbColor.contrast(Color('white'))

    return contrastValue.toFixed(2) + ' ' + getContrastScore(contrastValue)
  } catch (e) {
    return '-'
  }
}

export const getBoxShadowStyleForValues = (values: CreateTheme, type: string) => {
  return Object.keys(getBoxShadowFieldPath(type))
    .reduce<string[]>((acc, key) => {
      acc.push(getBoxShadowStyleFromInfo(get(values, `${getBoxShadowFieldPath(type)}.${key}`, {} as BoxShadow)))

      return acc
    }, [])
    .filter(Boolean)
    .join(', ')
}

const regExp = /(px|%)$/
export const valueToPixels = (value: number | string): string =>
  value ? `${replace(String(value), regExp, '')}px` : ''

export const getTypographyParams = (
  createTheme: CreateTheme,
  secondaryFont: MayBeNull<ThemeFont>,
  fontTypes: FontTypes,
  type: string,
): ThemeTypographyStyles => {
  let typographyStyles: ThemeTypographyStyles = {
    fontWeight: get(createTheme, `typography.${type}.fontWeight`, ''),
    fontSize: valueToPixels(get(createTheme, `typography.${type}.fontSize`, '')),
    lineHeight: valueToPixels(get(createTheme, `typography.${type}.lineHeight`, '')),
    letterSpacing: valueToPixels(get(createTheme, `typography.${type}.letterSpacing`, '')),
    fontFamily: createTheme.font.family,
  }

  const isSecondarySelected = get(fontTypes, `typography.${type}`) === 'secondary'

  if (isSecondarySelected && secondaryFont?.name) {
    typographyStyles = {
      ...typographyStyles,
      fontFamily: secondaryFont.name,
    }
  }

  return typographyStyles
}

export const addSuffixToPaletteColorFieldName = (fieldName: string = ''): string => `${fieldName}_color`
export const removeSuffixFromPaletteColorFieldName = (fieldName: string = '') =>
  fieldName.substring(0, fieldName.indexOf('_color'))

const getColorValue = (createTheme: CreateTheme, fieldPath: string, fieldName: string | number): string => {
  return get(createTheme, `${fieldPath}.${addSuffixToPaletteColorFieldName(String(fieldName))}`, '')
}

export const getTypographySectionInfo = (
  createTheme: CreateTheme,
  secondaryFont: MayBeNull<ThemeFont>,
  fontTypes: FontTypes,
): ThemeTypography => ({
  '5xl': {
    display: getTypographyParams(createTheme, secondaryFont, fontTypes, '5xl.display'),
  },
  '4xl': {
    display: getTypographyParams(createTheme, secondaryFont, fontTypes, '4xl.display'),
  },
  '3xl': {
    heading: getTypographyParams(createTheme, secondaryFont, fontTypes, '3xl.heading'),
  },
  '2xl': {
    heading: getTypographyParams(createTheme, secondaryFont, fontTypes, '2xl.heading'),
  },
  xl: {
    heading: getTypographyParams(createTheme, secondaryFont, fontTypes, 'xl.heading'),
  },
  l: {
    body: getTypographyParams(createTheme, secondaryFont, fontTypes, 'l.body'),
    midi: getTypographyParams(createTheme, secondaryFont, fontTypes, 'l.midi'),
    strong: getTypographyParams(createTheme, secondaryFont, fontTypes, 'l.strong'),
  },
  m: {
    body: getTypographyParams(createTheme, secondaryFont, fontTypes, 'm.body'),
    midi: getTypographyParams(createTheme, secondaryFont, fontTypes, 'm.midi'),
    strong: getTypographyParams(createTheme, secondaryFont, fontTypes, 'm.strong'),
  },
  s: {
    body: getTypographyParams(createTheme, secondaryFont, fontTypes, 's.body'),
    midi: getTypographyParams(createTheme, secondaryFont, fontTypes, 's.midi'),
    strong: getTypographyParams(createTheme, secondaryFont, fontTypes, 's.strong'),
  },
  xs: {
    body: getTypographyParams(createTheme, secondaryFont, fontTypes, 'xs.body'),
    midi: getTypographyParams(createTheme, secondaryFont, fontTypes, 'xs.midi'),
    strong: getTypographyParams(createTheme, secondaryFont, fontTypes, 'xs.strong'),
  },
  '2xs': {
    strong: getTypographyParams(createTheme, secondaryFont, fontTypes, '2xs.strong'),
  },
})

export const getThemeTypographyCreateThemeInfo = (appContext: IAppContext): Theme => {
  const { createTheme, secondaryFont, fontTypes } = appContext

  return {
    version: 1,
    settings: {
      fontUrl: compact([createTheme.font.url, secondaryFont?.link]),
    },
    content: {
      light: {
        font: {
          family: createTheme.font.family,
        },
        typography: getTypographySectionInfo(createTheme, secondaryFont, fontTypes),
      },
    },
  } as Theme
}

export const getThemeFromCreateThemeInfo = (appContext: IAppContext): Theme => {
  const { createTheme, secondaryFont, fontTypes, customFont, secondaryCustomFont } = appContext

  const mainFontLinks = [
    createTheme?.font?.family && createTheme?.font?.family === customFont?.name
      ? `https://public-assets.os.wpp.com/fonts/${customFont?.name}/index.css`
      : null,
    secondaryFont?.name && secondaryFont?.name === secondaryCustomFont?.name
      ? `https://public-assets.os.wpp.com/fonts/${secondaryCustomFont?.name}/index.css`
      : null,
  ]

  return {
    version: 1,
    settings: {
      fontUrl: compact([createTheme.font.url, secondaryFont?.link, ...mainFontLinks]),
    },
    content: {
      light: {
        color: {
          primary: {
            '100': getColorValue(createTheme, 'color.primary', 100),
            '200': getColorValue(createTheme, 'color.primary', 200),
            '300': getColorValue(createTheme, 'color.primary', 300),
            '400': getColorValue(createTheme, 'color.primary', 400),
            '500': getColorValue(createTheme, 'color.primary', 500),
            '600': getColorValue(createTheme, 'color.primary', 600),
            '700': getColorValue(createTheme, 'color.primary', 700),
            '800': getColorValue(createTheme, 'color.primary', 800),
          },
          success: {
            '200': getColorValue(createTheme, 'color.system.success', 200),
            '400': getColorValue(createTheme, 'color.system.success', 400),
            '500': getColorValue(createTheme, 'color.system.success', 500),
          },
          warning: {
            '200': getColorValue(createTheme, 'color.system.warning', 200),
            '400': getColorValue(createTheme, 'color.system.warning', 400),
            '500': getColorValue(createTheme, 'color.system.warning', 500),
          } as any,
          danger: {
            '200': getColorValue(createTheme, 'color.system.danger', 200),
            '300': getColorValue(createTheme, 'color.system.danger', 300),
            '400': getColorValue(createTheme, 'color.system.danger', 400),
            '500': getColorValue(createTheme, 'color.system.danger', 500),
            '600': getColorValue(createTheme, 'color.system.danger', 600),
          },
          grey: {
            '100': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 100),
            '200': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 200),
            '300': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 300),
            '400': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 400),
            '500': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 500),
            '600': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 600),
            '700': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 700),
            '800': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 800),
            '900': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 900),
            '1000': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, 1000),
            '000': getColorValue(createTheme, `color.grey.${createTheme?.color?.grey?.currSection}`, '000'),
          },
          highlight: {
            '200': getColorValue(createTheme, 'color.system.highlight', 200),
            '400': getColorValue(createTheme, 'color.system.highlight', 400),
          },
          brand: {
            default: 'color.primary.500',
            disabled: 'color.primary.300',
            hover: 'color.primary.400',
            active: 'color.primary.600',
          },
          dataviz: {
            cat: {
              dark: {
                '1': getColorValue(createTheme, 'color.dataviz.cat.dark', 1),
                '2': getColorValue(createTheme, 'color.dataviz.cat.dark', 2),
                '3': getColorValue(createTheme, 'color.dataviz.cat.dark', 3),
                '4': getColorValue(createTheme, 'color.dataviz.cat.dark', 4),
                '5': getColorValue(createTheme, 'color.dataviz.cat.dark', 5),
                '6': getColorValue(createTheme, 'color.dataviz.cat.dark', 6),
                '7': getColorValue(createTheme, 'color.dataviz.cat.dark', 7),
                '8': getColorValue(createTheme, 'color.dataviz.cat.dark', 8),
                '9': getColorValue(createTheme, 'color.dataviz.cat.dark', 9),
                '10': getColorValue(createTheme, 'color.dataviz.cat.dark', 10),
              },
              light: {
                '1': getColorValue(createTheme, 'color.dataviz.cat.light', 1),
                '2': getColorValue(createTheme, 'color.dataviz.cat.light', 2),
                '3': getColorValue(createTheme, 'color.dataviz.cat.light', 3),
                '4': getColorValue(createTheme, 'color.dataviz.cat.light', 4),
                '5': getColorValue(createTheme, 'color.dataviz.cat.light', 5),
                '6': getColorValue(createTheme, 'color.dataviz.cat.light', 6),
                '7': getColorValue(createTheme, 'color.dataviz.cat.light', 7),
                '8': getColorValue(createTheme, 'color.dataviz.cat.light', 8),
                '9': getColorValue(createTheme, 'color.dataviz.cat.light', 9),
                '10': getColorValue(createTheme, 'color.dataviz.cat.light', 10),
              },
              neutral: {
                '1': getColorValue(createTheme, 'color.dataviz.cat.neutral', 1),
                '2': getColorValue(createTheme, 'color.dataviz.cat.neutral', 2),
                '3': getColorValue(createTheme, 'color.dataviz.cat.neutral', 3),
                '4': getColorValue(createTheme, 'color.dataviz.cat.neutral', 4),
                '5': getColorValue(createTheme, 'color.dataviz.cat.neutral', 5),
                '6': getColorValue(createTheme, 'color.dataviz.cat.neutral', 6),
                '7': getColorValue(createTheme, 'color.dataviz.cat.neutral', 7),
                '8': getColorValue(createTheme, 'color.dataviz.cat.neutral', 8),
                '9': getColorValue(createTheme, 'color.dataviz.cat.neutral', 9),
                '10': getColorValue(createTheme, 'color.dataviz.cat.neutral', 10),
              },
            },
            seq: {
              brand: {
                '100': getColorValue(createTheme, 'color.dataviz.seq.brand', 100),
                '200': getColorValue(createTheme, 'color.dataviz.seq.brand', 200),
                '300': getColorValue(createTheme, 'color.dataviz.seq.brand', 300),
                '400': getColorValue(createTheme, 'color.dataviz.seq.brand', 400),
                '500': getColorValue(createTheme, 'color.dataviz.seq.brand', 500),
                '600': getColorValue(createTheme, 'color.dataviz.seq.brand', 600),
                '700': getColorValue(createTheme, 'color.dataviz.seq.brand', 700),
                '800': getColorValue(createTheme, 'color.dataviz.seq.brand', 800),
                '900': getColorValue(createTheme, 'color.dataviz.seq.brand', 900),
                '1000': getColorValue(createTheme, 'color.dataviz.seq.brand', 1000),
              },
              grey: {
                '100': getColorValue(createTheme, 'color.dataviz.seq.grey', 100),
                '200': getColorValue(createTheme, 'color.dataviz.seq.grey', 200),
                '300': getColorValue(createTheme, 'color.dataviz.seq.grey', 300),
                '400': getColorValue(createTheme, 'color.dataviz.seq.grey', 400),
                '500': getColorValue(createTheme, 'color.dataviz.seq.grey', 500),
                '600': getColorValue(createTheme, 'color.dataviz.seq.grey', 600),
                '700': getColorValue(createTheme, 'color.dataviz.seq.grey', 700),
                '800': getColorValue(createTheme, 'color.dataviz.seq.grey', 800),
                '900': getColorValue(createTheme, 'color.dataviz.seq.grey', 900),
                '1000': getColorValue(createTheme, 'color.dataviz.seq.grey', 1000),
              },
              negative: {
                '100': getColorValue(createTheme, 'color.dataviz.seq.negative', 100),
                '200': getColorValue(createTheme, 'color.dataviz.seq.negative', 200),
                '300': getColorValue(createTheme, 'color.dataviz.seq.negative', 300),
                '400': getColorValue(createTheme, 'color.dataviz.seq.negative', 400),
                '500': getColorValue(createTheme, 'color.dataviz.seq.negative', 500),
                '600': getColorValue(createTheme, 'color.dataviz.seq.negative', 600),
                '700': getColorValue(createTheme, 'color.dataviz.seq.negative', 700),
                '800': getColorValue(createTheme, 'color.dataviz.seq.negative', 800),
                '900': getColorValue(createTheme, 'color.dataviz.seq.negative', 900),
                '1000': getColorValue(createTheme, 'color.dataviz.seq.negative', 1000),
              },
              warning: {
                '100': getColorValue(createTheme, 'color.dataviz.seq.warning', 100),
                '200': getColorValue(createTheme, 'color.dataviz.seq.warning', 200),
                '300': getColorValue(createTheme, 'color.dataviz.seq.warning', 300),
                '400': getColorValue(createTheme, 'color.dataviz.seq.warning', 400),
                '500': getColorValue(createTheme, 'color.dataviz.seq.warning', 500),
                '600': getColorValue(createTheme, 'color.dataviz.seq.warning', 600),
                '700': getColorValue(createTheme, 'color.dataviz.seq.warning', 700),
                '800': getColorValue(createTheme, 'color.dataviz.seq.warning', 800),
                '900': getColorValue(createTheme, 'color.dataviz.seq.warning', 900),
                '1000': getColorValue(createTheme, 'color.dataviz.seq.warning', 1000),
              },
              positive: {
                '100': getColorValue(createTheme, 'color.dataviz.seq.positive', 100),
                '200': getColorValue(createTheme, 'color.dataviz.seq.positive', 200),
                '300': getColorValue(createTheme, 'color.dataviz.seq.positive', 300),
                '400': getColorValue(createTheme, 'color.dataviz.seq.positive', 400),
                '500': getColorValue(createTheme, 'color.dataviz.seq.positive', 500),
                '600': getColorValue(createTheme, 'color.dataviz.seq.positive', 600),
                '700': getColorValue(createTheme, 'color.dataviz.seq.positive', 700),
                '800': getColorValue(createTheme, 'color.dataviz.seq.positive', 800),
                '900': getColorValue(createTheme, 'color.dataviz.seq.positive', 900),
                '1000': getColorValue(createTheme, 'color.dataviz.seq.positive', 1000),
              },
            },
          },
          white: 'color.grey.000',
        },
        border: {
          radius: {
            xs: valueToPixels(createTheme.objectStyles.border.radius.xs),
            s: valueToPixels(createTheme.objectStyles.border.radius.s),
            m: valueToPixels(createTheme.objectStyles.border.radius.m),
            l: valueToPixels(createTheme.objectStyles.border.radius.l),
            xl: valueToPixels(createTheme.objectStyles.border.radius.xl),
            round: valueToPixels(createTheme.objectStyles.border.radius.round),
          },
          width: {
            s: '1px',
            m: '2px',
            l: '3px',
          },
        },
        boxShadow: {
          xs: getBoxShadowStyleForValues(createTheme, 'xs'),
          s: getBoxShadowStyleForValues(createTheme, 's'),
          m: getBoxShadowStyleForValues(createTheme, 'm'),
          brand: getBoxShadowStyleForValues(createTheme, 'brand'),
          l: getBoxShadowStyleForValues(createTheme, 'l'),
        },
        font: {
          family: createTheme.font.family,
        },
        icon: {
          color: {
            default: 'color.grey.600',
            disabled: 'color.grey.400',
            hover: 'color.grey.800',
            active: 'color.grey.900',
          },
        },
        text: {
          color: {
            default: 'color.grey.1000',
            whiteInfo: 'color.grey.200',
            disabled: 'color.grey.500',
            danger: 'color.danger.500',
            warning: 'color.warning.500',
            success: 'color.success.500',
            info: 'color.grey.800',
          },
        },
        surface: {
          bgColor: 'color.grey.100',
        },
        overlay: {
          bgColor: 'rgba(77, 83, 88, 0.6)',
        },
        typography: getTypographySectionInfo(createTheme, secondaryFont, fontTypes),
      },
    },
  }
}

const convertThemeColorSectionToCreateThemeColorSection = (
  themeSection: Record<string, string> = {},
): Record<string, string> =>
  Object.entries(themeSection).reduce<Record<string, string>>((acc, [key, value]) => {
    acc[addSuffixToPaletteColorFieldName(key)] = getColorInfo(value).hex
    return acc
  }, {})

const convertThemeBoxShadowToCreateThemeBoxShadow = (theme: ThemeStyles) => {
  return Object.entries(theme.boxShadow || {}).reduce<Record<string, Record<string, BoxShadow>>>(
    (acc, [key, value]) => {
      const parseResult: BoxShadowParseResult[] = parse(value)

      acc[key] = parseResult.reduce<Record<string, BoxShadow>>((acc, boxShadowItem, index) => {
        acc[index] = {
          x: boxShadowItem.offsetX,
          y: boxShadowItem.offsetY,
          blur: boxShadowItem.blurRadius,
          spread: boxShadowItem.spreadRadius,
          color: getColorInfo(boxShadowItem.color).hex,
          opacity: getColorInfo(boxShadowItem.color).opacity,
        }
        return acc
      }, {})

      return acc
    },
    {},
  )
}

export const getCreateThemeInfoFromTheme = (theme: ThemeStyles): any => {
  return {
    font: {
      family: '',
      url: '',
    },
    name: '',
    color: {
      primary: convertThemeColorSectionToCreateThemeColorSection(theme.color.primary),
      grey: {
        cold: convertThemeColorSectionToCreateThemeColorSection(theme.color.grey),
        neutral: {},
        currSection: 'cold',
      },
      dataviz: {
        seq: {
          brand: convertThemeColorSectionToCreateThemeColorSection(theme.color.dataviz.seq.brand),
          grey: convertThemeColorSectionToCreateThemeColorSection(theme.color.dataviz.seq.grey),
          negative: convertThemeColorSectionToCreateThemeColorSection(theme.color.dataviz.seq.negative),
          warning: convertThemeColorSectionToCreateThemeColorSection(theme.color.dataviz.seq.warning),
          positive: convertThemeColorSectionToCreateThemeColorSection(theme.color.dataviz.seq.positive),
          currSection: 'brand',
        },
        cat: {
          dark: convertThemeColorSectionToCreateThemeColorSection(theme.color.dataviz.cat.dark),
          light: convertThemeColorSectionToCreateThemeColorSection(theme.color.dataviz.cat.light),
          neutral: convertThemeColorSectionToCreateThemeColorSection(theme.color.dataviz.cat.neutral),
          currSection: 'dark',
        },
      },
      system: {
        danger: convertThemeColorSectionToCreateThemeColorSection(theme.color.danger),
        warning: convertThemeColorSectionToCreateThemeColorSection(theme.color.warning),
        success: convertThemeColorSectionToCreateThemeColorSection(theme.color.success),
        highlight: convertThemeColorSectionToCreateThemeColorSection(theme.color.highlight),
      },
    },
    objectStyles: {
      border: {
        radius: Object.entries(theme.border.radius).reduce<Record<string, number | string>>((acc, [key, value]) => {
          acc[key] = String(value)

          return acc
        }, {}),
      },
      boxShadow: convertThemeBoxShadowToCreateThemeBoxShadow(theme),
    },
    typography: theme.typography,
  }
}

const getFontNameFromFontUrl = (url: string): MayBeNull<string> => {
  const regex = /\/([^/]+)(?:\/index\.css|\.css)$/
  const match = url.match(regex)

  // If a match is found, return the font name, otherwise return null
  return match ? match[1] : ''
}

const isCustomFont = (url: string): boolean => !themesFonts.map(font => font.link).includes(url)

const getFontFromLink = (fontLink: string): { font: ThemeFont; isCustom: boolean } => {
  const isFontCustom = isCustomFont(fontLink)

  const font: ThemeFont = isFontCustom
    ? {
        link: fontLink,
        name: getFontNameFromFontUrl(fontLink) || '',
      }
    : (themesFonts.find(themeFont => themeFont.link === fontLink) as ThemeFont)

  return {
    isCustom: isFontCustom,
    font,
  }
}

export const retrieveFontsData = (
  initFontsLinks: string[] | string,
): {
  primaryFont: ThemeFont
  isPrimaryFontCustom: boolean
  secondaryFont: ThemeFont | null
  isSecondaryFontCustom: boolean
} => {
  const fontsLinks = Array.isArray(initFontsLinks) ? initFontsLinks : [initFontsLinks]

  const firstLinkFont = getFontFromLink(fontsLinks[0])
  const secondLinkFont = fontsLinks[1] ? getFontFromLink(fontsLinks[1]) : null

  const isSecondLinkSecondaryFont = firstLinkFont.font.name !== secondLinkFont?.font.name

  return {
    primaryFont: firstLinkFont.font,
    isPrimaryFontCustom: firstLinkFont.isCustom,
    secondaryFont: isSecondLinkSecondaryFont && secondLinkFont ? secondLinkFont.font : null,
    isSecondaryFontCustom: isSecondLinkSecondaryFont && secondLinkFont ? secondLinkFont.isCustom : false,
  }
}
